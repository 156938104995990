import htmx from 'htmx.org'
import posthog from 'posthog-js'
import Cookies, { CookieChangeListener } from 'universal-cookie'
import LazyLoad from 'vanilla-lazyload'
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'

const trackingCookieName = 'goodcrowd-tracking-accepted'
const cookies = new Cookies()

const initPosthog = () => {
  const { posthogApiKey, posthogApiHost } = document.body.dataset

  if (posthogApiKey && posthogApiHost && !posthog.__loaded) {
    posthog.init(posthogApiKey, {
      api_host: posthogApiHost,
      capture_pageview: true,
      capture_pageleave: true,
      autocapture: false,
      disable_session_recording: true,
      person_profiles: 'always',
    })
  }
}

const handleCookieChange: CookieChangeListener = (cookie) => {
  if (cookie.name === trackingCookieName && cookie.value == 'accepted') {
    initPosthog()
  }
}

cookies.addChangeListener(handleCookieChange)

if (cookies.get(trackingCookieName) === 'accepted') {
  initPosthog()
}

class AlignCenterElement extends HTMLElement {
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = `
      <style>
        :host {
          text-align: center;
          width: 100%;
          display: block;
        }
      </style>

      <slot></slot>
    `
    }
  }
}

htmx.onLoad(() => {
  if (!window.customElements.get('text-center')) {
    window.customElements.define('text-center', AlignCenterElement)
  }
})

// Lazy Loading
const lazyLoader = new LazyLoad()

htmx.onLoad(() => {
  lazyLoader.update()
})

htmx.onLoad(() => {
  const selectors = ['.glide', '#how-it-works']

  for (const selector of selectors) {
    if (document.querySelector(selector)) {
      new Glide(selector, {
        perView: 2,
        peek: 40,
        breakpoints: {
          450: { perView: 1 },
        },
      }).mount()
    }
  }
})

// Open a close the desktop dropdown menu in the navbar
document.addEventListener('DOMContentLoaded', function (event) {
  const userMenuButton = document.getElementById('user-menu-button')
  const userMenu = document.getElementById('user-menu')

  if (!userMenuButton || !userMenu) {
    return
  }

  const closeDropdown = (event: MouseEvent) => {
    const withinBoundaries = event.composedPath().includes(userMenu)

    if (!withinBoundaries && !userMenu.classList.contains('hidden')) {
      userMenu.classList.add('hidden')
      document.removeEventListener('click', closeDropdown)
    }
  }

  const openDropdown = (event: MouseEvent) => {
    event.stopPropagation()

    if (userMenu.classList.contains('hidden')) {
      userMenu.classList.remove('hidden')
      document.addEventListener('click', closeDropdown)
    } else {
      userMenu.classList.add('hidden')
      document.removeEventListener('click', closeDropdown)
    }
  }

  userMenuButton.addEventListener('click', openDropdown)
})

// Open and close mobile menu
document.addEventListener('DOMContentLoaded', function (event) {
  const mobileMenuButton = document.getElementById('mobile-menu-button')
  const mobileMenu = document.getElementById('mobile-menu')

  if (!mobileMenu || !mobileMenuButton) {
    return
  }

  const outsideClick = (event: MouseEvent) => {
    const withinBoundaries = event.composedPath().includes(mobileMenu)

    if (!withinBoundaries && !mobileMenu.classList.contains('hidden')) {
      mobileMenu.classList.add('hidden')
      document.removeEventListener('click', outsideClick)
    }
  }

  mobileMenuButton.addEventListener('click', (event: MouseEvent) => {
    event.stopPropagation()

    if (mobileMenu.classList.contains('hidden')) {
      mobileMenu.classList.remove('hidden')
      document.addEventListener('click', outsideClick)
    } else {
      mobileMenu.classList.add('hidden')
      document.removeEventListener('click', outsideClick)
    }
  })
})

// Search form in header
document.addEventListener('DOMContentLoaded', function (event) {
  const searchForm = document.getElementById('search-form')
  const showSearchButton = document.getElementById('show-search-button')

  if (!searchForm || !showSearchButton) {
    return
  }

  showSearchButton.addEventListener('click', () => {
    const searchFormInput = searchForm.querySelector('input')

    searchForm.classList.toggle('hidden')
    showSearchButton.classList.toggle('hidden')

    if (searchFormInput) {
      searchFormInput.focus()
    }
  })
})

// Search form in header (mobile)
document.addEventListener('DOMContentLoaded', function (event) {
  const searchForm = document.getElementById('mobile-search-form')
  const showSearchButton = document.getElementById('mobile-show-search-button')

  if (!searchForm || !showSearchButton) {
    return
  }

  showSearchButton.addEventListener('click', () => {
    searchForm.classList.toggle('hidden')
    showSearchButton.classList.toggle('hidden')
  })
})

function heroTypeEffect() {
  let currentPhrase: string = ''
  let isDeleting: boolean = false
  let phraseIndex: number = 0
  let typingSpeed: number = 60

  const phraseElement = document.getElementById('typing-text')

  function typeEffect(): void {
    if (!phraseElement || !phraseElement.dataset.claims) return

    const phrases = JSON.parse(phraseElement.dataset.claims)
    const currentFullPhrase: string = phrases[phraseIndex]
    const speed: number = isDeleting ? 30 : 60

    if (!isDeleting && currentPhrase === currentFullPhrase) {
      setTimeout(() => {
        isDeleting = true
        typeEffect()
      }, 2000)
      return
    } else if (isDeleting && currentPhrase === '') {
      isDeleting = false
      phraseIndex = (phraseIndex + 1) % phrases.length
      setTimeout(typeEffect, 500) // Pause before typing the next phrase
      return
    }

    currentPhrase = isDeleting
      ? currentPhrase.substring(0, currentPhrase.length - 1)
      : currentFullPhrase.substring(0, currentPhrase.length + 1)

    phraseElement.innerHTML = `${currentPhrase}<span class="border-r-4 border-white animate-blink">&nbsp;</span>`
    typingSpeed = speed

    setTimeout(typeEffect, typingSpeed)
  }

  console.log('load tuping effect')
  typeEffect()
}

document.addEventListener('htmx:afterSettle', heroTypeEffect)
document.addEventListener('DOMContentLoaded', heroTypeEffect)
window.addEventListener('htmx:historyRestore', heroTypeEffect)
